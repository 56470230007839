'use strict';

Gri.module({
  name: 'section-beta',
  ieVersion: null,
  $el: $('.section-beta'),
  container: '.section-beta',
  fn: function () {
    
  }
});
